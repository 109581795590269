<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Change Password </span>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center py-4>
      <v-flex xs12 lg10>
        <v-layout wrap justify-start>
          <v-flex xs12 text-left px-2>
            <span class="itemValue">Current Password</span>
            <v-text-field
              ref="password"
              color="#005f32"
              placeholder="••••••••" 
              dense
              v-model="oldPassword"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 text-left px-2>
            <span class="itemValue">New Password</span>
            <v-text-field
              ref="lastname"
              color="#005f32"
              placeholder="••••••••" 
              dense
              v-model="password"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              counter
              @click:append="show2 = !show2"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 text-left px-2>
            <span class="itemValue">Confirm New Password</span>
            <v-text-field
              ref="lastname"
              color="#005f32"
              placeholder="••••••••"
              dense
              v-model="confirmpassword"
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show3 ? 'text' : 'password'"
              counter
              @click:append="show3 = !show3"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 sm6 xl3 pa-2>
        <v-btn
          block
          tile
          outlined
          color="#FF1313"
          light
          :ripple="false"
          depressed
          @click="closeDialoge"
          class="itemValue"
        >
          Cancel
        </v-btn>
      </v-flex>
      <v-flex xs12 sm6 xl3 pa-2>
        <v-btn
          block
          tile
          color="#005f32"
          dark
          :ripple="false"
          depressed
          @click="validateInput"
          class="itemValue"
        >
          Change Password
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      password: null,
      oldPassword: null,
      confirmpassword: null,
       show1: false,
        show2: true,
        show3: false,
    };
  },
  methods: {
    closeDialoge() {
      this.$emit("stepper", {
        type: "Password",
        getData: false,
        changePassword: false,
        status: false,
      });
    },
    validateInput() {
      if (!this.oldPassword) {
        this.msg = "Please Provide Current Password";
        this.showSnackBar = true;
        return;
      } else if (!this.password) {
        this.msg = "Please Provide New Password";
        this.showSnackBar = true;
        return;
      } else if (this.password != this.confirmpassword) {
        this.msg = " Password do not match!";
        this.showSnackBar = true;
        return;
      } else {
        this.changePassword();
      }
    },
    changePassword() {
      var userData = {};
      userData["newPassword"] = this.password;
      userData["oldPassword"] = this.oldPassword;
      axios({
        method: "post",
        url: "/employee/change/password",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.$emit("stepper", {
              type: "Password",
              getData: false,
              changePassword: false,
              status: true,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
