<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Edit Profile </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4 v-if="storage">
      <v-flex xs12 lg10>
        <v-layout wrap justify-start py-4>
          <v-flex xs12 text-left px-2>
            <!-- <v-avatar color="#FFF9C4" size="100"> -->
            <!-- <v-img v-if="profilePic" :src="profilePic">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#005f32"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-img
                v-if="!profilePic && storage.photo"
                :src="mediaURL + storage.photo"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#005f32"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img> -->

            <ImageComp
              @stepper="winStepper"
              :singleImage="storage.photo"
              :pageId="storage._id"
              :height="'320'"
              :width="'320'"
              :heading="''"
              :componentType="'coverImage'"
            />
            <!-- </v-avatar> -->
          </v-flex>
          <!-- <v-flex xs6 text-left align-self-center px-2>
            <v-btn
              tile
              color="#005f32"
              light
              :ripple="false"
              depressed
              dark
              class="itemValue"
              @click="$refs.uploadProfilePic.click()"
            >
              Change Photo
            </v-btn>
            <input
              v-show="false"
              accept="image/*"
              ref="uploadProfilePic"
              type="file"
              @change="uploadProfilePic"
            />
          </v-flex> -->
        </v-layout>
        <v-layout wrap justify-start>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">DOB</span>

            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dob"
                  placeholder="DOB"
                  color="#005f32"
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dob"
                no-title
                @input="
                  menu = false;
                  getAge(dob);
                "
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Age</span>
            <v-text-field
              ref="Age"
              color="#005f32"
              placeholder="Age"
              dense
              v-model="age"
              readonly
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Address</span>
            <v-textarea
              ref="address"
              color="#005f32"
              placeholder="Address"
              dense
              v-model="address"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="closeDialoge"
              class="itemValue"
            >
              Cancel
            </v-btn>
          </v-flex>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              color="#005f32"
              dark
              :ripple="false"
              depressed
              @click="validateInput"
              class="itemValue"
            >
              Edit Profile
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "./singleImage";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,

      firstname: null,
      lastname: null,
      username: null,
      // password: null,
      phone: null,
      email: null,
      country: null,
      userType: "Admin",
      locationname: null,
      gender: "Male",
      profilePicImage: null,
      profilePic: null,
      genders: [
        { name: "Male", value: "Male" },
        { name: "Female", value: "Female" },
        { name: "Others", value: "Others" },
      ],
      userTypes: [
        { name: "User", value: "User" },
        { name: "Admin", value: "Admin" },
      ],
      msg_email: null,
      msg_phone: null,

      age: null,
      address: null,
      dob: null,
      menu: false,
      coverImageFile: null,
      formDataCover: new FormData(),
    };
  },
  components: {
    ImageComp,
  },
  beforeMount() {
    if (this.storage.dob) this.dob = this.storage.dob.slice(0, 10);
    if (this.storage.age) this.age = this.storage.age;
    if (this.storage.address) this.address = this.storage.address;
  },
  watch: {
    // phone() {
    //   axios({
    //     method: "POST",
    //     url: "/user/check/phone",
    //     data: {
    //       phone: this.phone,
    //       id: this.storage._id,
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status == false) this.msg_phone = response.data.msg;
    //       else this.msg_phone = null;
    //     })
    //     .catch((err) => {
    //       this.msg = err;
    //       this.ServerError = true;
    //     });
    // },
    // email() {
    //   axios({
    //     method: "POST",
    //     url: "/user/check/email",
    //     data: {
    //       email: this.email,
    //       id: this.storage._id,
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status == false) this.msg_email = response.data.msg;
    //       else this.msg_email = null;
    //     })
    //     .catch((err) => {
    //       this.msg = err;
    //       this.ServerError = true;
    //     });
    // },
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "coverImage") {
        this.coverImageFile = window_data.selectedFiles;
      }
    },
    closeDialoge() {
      this.$emit("stepper", {
        type: "Edit",
        getData: false,
        editProfile: false,
      });
    },
    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      // return age;
      this.age = age;
    },

   
    validateInput() {
      // if (!this.dob) {
      //   this.msg = "Please Provide DOB";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.age) {
      //   this.msg = "Please Provide Age";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.address) {
      //   this.msg = "Please Provide address";
      //   this.showSnackBar = true;
      //   return;
      // } else {
        this.editProfile();
      // }
    },
    editProfile() {
      var userData = {};
      userData["dob"] = this.dob;
      userData["age"] = this.age;
      userData["address"] = this.address;
      axios({
        method: "post",
        url: "/employee/profile/edit/v1",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Profile Updated";
            this.showSnackBar = true;
            if (this.coverImageFile) {
              this.uploadCoverImages();
              return;
            }
            this.$emit("stepper", {
              type: "Edit",
              getData: true,
              editProfile: false,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    uploadCoverImages() {
      this.flagg = false;
      this.appLoading = true;
      this.formDataCover.append("photo", this.coverImageFile);
      axios({
        method: "POST",
        url: "/employee/profile/edit/v1",
        data: this.formDataCover,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.formDataCover = new FormData();
            this.msg = response.data.msg;
            this.showSnackBar=true
          } else {
            this.msg = "Can't ";
            this.showSnackBar = true;
            this.formDataCover = new FormData();
          }
          this.getData();
          this.flagg = true;
        })
        .catch((err) => {
          this.appLoading = false;
          // this.msg = "Can't Upload Profile Image.. Please Try Again Later";
          // this.showSnackBar = true;
          console.log(err);
        });
    },
  },
};
</script>
